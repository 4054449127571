<template lang="pug">
  .account-info-item.d-flex
    .name.m-t-3
      | {{ name }}
    .w-100
      slot(name="values")
</template>

<script>
  export default {
    props: {
      name: {
        type: String,
        default: ""
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .account-info-item
    margin-top: 5px
    border-bottom: 1px solid $default-purple-light-line

    &:first-child
      margin-top: 30px !important

    &:last-child
      border: 0

  .name
    color: $icon-color
    font-size: 0.8rem
    font-weight: 400
    width: 150px

  .value
    color: $default-black
    font-size: 0.8rem
    font-weight: 400
    padding: 5px 0
</style>
